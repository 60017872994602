import React from 'react';
import './Services.css';

const Services = () => {
  return (
    <div className="services-page">
      <div className="container">
        <div className="intro-section">
          <h1>Our Services</h1>
          <p>
            At Pioneer Engineering & Construction, we offer a comprehensive range of services that cater to all your construction and engineering needs. Our commitment to quality, innovation, and customer satisfaction drives us to deliver outstanding results in every project we undertake.
          </p>
        </div>

        <div className="service-section">
          <h2>Construction Services</h2>
          <p>
            We specialize in delivering high-quality construction services for residential, commercial, and industrial projects. Our team of skilled professionals ensures that every aspect of your construction project is executed with precision and care, from the initial planning stages to the final build.
          </p>
        </div>

        <div className="service-section">
          <h2>Engineering Solutions</h2>
          <p>
            Our engineering solutions are tailored to meet the specific needs of each client. Whether it's civil, structural, mechanical, or electrical engineering, we provide innovative and efficient solutions that meet the highest standards of safety and quality.
          </p>
        </div>

        <div className="service-section">
          <h2>Project Management</h2>
          <p>
            Effective project management is key to the success of any project. Our experienced project managers work closely with clients to ensure that projects are completed on time, within budget, and to the highest standards. We manage all aspects of the project lifecycle, from planning and design to execution and completion.
          </p>
        </div>

        <div className="service-section">
          <h2>Consultancy Services</h2>
          <p>
            Our consultancy services provide clients with expert advice and guidance on a wide range of construction and engineering-related matters. Whether you need help with project planning, regulatory compliance, or technical challenges, our consultants are here to help you make informed decisions.
          </p>
        </div>

        <div className="service-section">
          <h2>Sustainability Consulting</h2>
          <p>
            Sustainability is at the heart of everything we do. Our sustainability consulting services help clients integrate eco-friendly practices into their projects. From green building designs to energy-efficient solutions, we provide the expertise you need to create sustainable and environmentally responsible projects.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Services;
