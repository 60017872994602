import React from 'react';
import { Link } from 'react-router-dom';
import './Investors.css';

const Investors = () => {
  return (
    <div className="investors-page">
      {/* Hero Section */}
      <section className="hero-investors">
        <div className="container">
          <h1>Invest in Pioneer Engineering & Construction</h1>
          <p>Join us in building a brighter future for East Africa through innovation, quality, and excellence.</p>
        </div>
      </section>

      {/* Introduction Section */}
      <section className="introduction-investors">
        <div className="container">
          <h2>Why Invest in Us?</h2>
          <p>
            At Pioneer Engineering & Construction, we are committed to revolutionizing the infrastructure of East Africa.
            Our projects are designed to not only meet the highest standards but also to drive economic growth and 
            improve the quality of life in the communities we serve.
          </p>
        </div>
      </section>

      {/* Key Statistics Section */}
      <section className="key-statistics">
        <div className="container">
          <h2>Key Statistics</h2>
          <div className="statistics-cards">
            <div className="card">
              <h3>230+</h3>
              <p>Employees Dedicated to Excellence</p>
            </div>
            <div className="card">
              <h3>75</h3>
              <p>Successful Projects Completed</p>
            </div>
            <div className="card">
              <h3>28%</h3>
              <p>Annual Growth Rate</p>
            </div>
            <div className="card">
              <h3>$2.75M</h3>
              <p>Annual Revenue</p>
            </div>
          </div>
        </div>
      </section>

      {/* Reasons to Invest Section */}
      <section className="reasons-to-invest">
        <div className="container">
          <h2>Reasons to Invest</h2>
          <ul>
            <li>Strong track record of delivering high-quality projects on time and within budget.</li>
            <li>Strategic position in the growing East African market.</li>
            <li>Experienced management team with a proven history of success.</li>
            <li>Commitment to sustainability and community development.</li>
          </ul>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="cta-investors">
        <div className="container">
          <h2>Ready to Invest?</h2>
          <p>Contact us today to learn more about our investment opportunities and how you can be part of our journey to build a better future.</p>
          <a href="mailto:dhismopioneer@gmail.com" className="cta-button">Contact Us</a>
          
        </div>
      </section>
    </div>
  );
};

export default Investors;
