import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import AboutUs from './components/AboutUs';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import BottomNav from './components/BottomNav';
import Services from './components/Services';
import ProjectGallery from './components/ProjectGallery';
import ContactUs from './components/ContactUs';
import Investors from './components/Investors';
import Jobs from './components/Jobs'; 
import EmailVerification from './components/EmailVerification'; 
import BOIRForm from './components/BOIRForm'; 
import SquarePayment from "./components/SquarePayment";

import './App.css';

function App() {
  return (
    <Router>
      <NavBar />
      {/*<EmailVerification />*/}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/projects" element={<ProjectGallery />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/investors" element={<Investors />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/email-verification" element={<EmailVerification />} />
          <Route path="/boir-form" element={<BOIRForm />} />
          <Route path="/payment" element={<SquarePayment />} />
        {/* Add other routes here */}
      </Routes>
      <BottomNav />
    </Router>
  );
}

export default App;
