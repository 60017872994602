import React from 'react';
import './ProjectGallery.css';

const projects = [
  {
    title: "Residential Apartment Complex",
    description: "A state-of-the-art residential apartment complex designed to provide luxury and comfort.",
    image: "https://pioneeriya.com//mybackend/images/project1.jpg"
  },
  {
    title: "Residential Apartment Complex",
    description: "This high-rise residential apartment .",
    image: "https://pioneeriya.com/mybackend/images/project2.jpg"
  },
  {
    title: "Residential Apartment Complex",
    
    image: "https://pioneeriya.com/mybackend/images/project3.jpg"
  },
  {
    title: "Residential Apartment Complex",
    
    image: "https://pioneeriya.com/mybackend/images/project4.jpg"
  },
  {
    title: "Residential Apartment Complex",
    description: "A sustainable housing project designed to provide affordable and eco-friendly homes.",
    image: "https://pioneeriya.com/mybackend/images/project5.jpg"
  }
];

const ProjectGallery = () => {
  return (
    <div className="project-gallery">
      <div className="container">
        <div className="intro-section">
          <h1>Project Gallery</h1>
          <p>
            Explore our diverse range of projects that showcase our commitment to quality, innovation, and sustainability.
          </p>
        </div>

        <div className="gallery-grid">
          {projects.map((project, index) => (
            <div key={index} className="gallery-item">
              <img src={project.image} alt={project.title} />
              <h3>{project.title}</h3>
              <p>{project.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectGallery;
