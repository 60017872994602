import React from 'react';
import './Jobs.css';

const Jobs = () => {
  return (
    <div className="jobs-page">
      {/* Hero Section */}
      <section className="hero-jobs">
        <div className="container">
          <h1>Join Our Team at Pioneer Engineering & Construction</h1>
          <p>We are always looking for talented individuals to help us build a brighter future across East Africa.</p>
        </div>
      </section>

      {/* Working at Pioneer Section */}
      <section className="working-at-pioneer">
        <div className="container">
          <h2>Why Work at Pioneer?</h2>
          <p>
            At Pioneer Engineering & Construction, we believe that our employees are our greatest asset. We offer a dynamic and inclusive work environment where innovation is encouraged and excellence is rewarded.
          </p>
          <ul>
            <li>Competitive Salaries and Benefits</li>
            <li>Opportunities for Career Growth</li>
            <li>Comprehensive Training Programs</li>
            <li>Supportive and Inclusive Culture</li>
          </ul>
        </div>
      </section>

      {/* Available Positions Section */}
      <section className="available-positions">
        <div className="container">
          <h2>Current Job Openings</h2>
          <div className="positions-list">
            <div className="position">
              <h3>Civil Engineer</h3>
              <p>Location: Mogadishu, Somalia</p>
              <p>We are looking for an experienced Civil Engineer to join our team and work on large-scale infrastructure projects.</p>
              <a href="mailto:dhismopioneer@gmail.com?subject=Civil Engineer Application" className="apply-button">Apply Now</a>
            </div>
            <div className="position">
              <h3>Project Manager</h3>
              <p>Location: Nairobi, Kenya</p>
              <p>We need a skilled Project Manager to oversee our construction projects and ensure they are completed on time and within budget.</p>
              <a href="mailto:dhismopioneer@gmail.com?subject=Project Manager Application" className="apply-button">Apply Now</a>
            </div>
            <div className="position">
              <h3>Architect</h3>
              <p>Location: Addis Ababa, Ethiopia</p>
              <p>We are seeking a creative Architect to design innovative structures that meet our clients' needs.</p>
              <a href="mailto:dhismopioneer@gmail.com?subject=Architect Application" className="apply-button">Apply Now</a>
            </div>
            <div className="position">
              <h3>Construction Foreman</h3>
              <p>Location: Mogadishu, Somalia</p>
              <p>We are looking for a hands-on Construction Foreman to lead and manage our construction crews on site.</p>
              <a href="mailto:careers@pioneer-eng.com?subject=Construction Foreman Application" className="apply-button">Apply Now</a>
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="cta-jobs">
        <div className="container">
          <h2>Don't See a Position That Fits?</h2>
          <p>We're always interested in hearing from talented individuals. If you don't see a position that matches your skills, feel free to send us your resume.</p>
          <a href="dhismopioneer@gmail.com?subject=General Job Inquiry" className="cta-button">Submit Your Resume</a>
        </div>
      </section>
    </div>
  );
};

export default Jobs;
