import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import './BOIRForm.css';

const BOIRForm = () => {
  const [companyDetails, setCompanyDetails] = useState({
    companyName: "",
    companyEIN: "",
    companyAddress: "",
    numberOfOwners: 1,
  });

  const [owners, setOwners] = useState([]);
  const [formErrors, setFormErrors] = useState({}); // For validation errors
  const navigate = useNavigate();

  const handleCompanyChange = (e) => {
    setCompanyDetails({ ...companyDetails, [e.target.name]: e.target.value });
  };

  const handleOwnerChange = (index, e) => {
    const updatedOwners = [...owners];
    updatedOwners[index] = { ...updatedOwners[index], [e.target.name]: e.target.value };
    setOwners(updatedOwners);
  };

  const handleFileUpload = (index, e) => {
    const file = e.target.files[0];
    const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg'];
    
    if (!allowedTypes.includes(file.type)) {
      const updatedErrors = { ...formErrors };
      updatedErrors[`idFile${index}`] = "Only PDF, PNG, JPG, and JPEG files are allowed.";
      setFormErrors(updatedErrors);
      return;
    }
    
    const updatedOwners = [...owners];
    updatedOwners[index] = { ...updatedOwners[index], idFile: file };
    setOwners(updatedOwners);

    // Clear any previous file-related error
    const updatedErrors = { ...formErrors };
    delete updatedErrors[`idFile${index}`];
    setFormErrors(updatedErrors);
  };

  const validateForm = () => {
    let errors = {};
    if (!companyDetails.companyName) errors.companyName = "Company name is required.";
    if (!companyDetails.companyEIN) errors.companyEIN = "Company EIN is required.";
    if (!companyDetails.companyAddress) errors.companyAddress = "Company address is required.";

    owners.forEach((owner, index) => {
      if (!owner.firstName) errors[`firstName${index}`] = `First name for Owner ${index + 1} is required.`;
      if (!owner.lastName) errors[`lastName${index}`] = `Last name for Owner ${index + 1} is required.`;
      if (!owner.dob) errors[`dob${index}`] = `Date of Birth for Owner ${index + 1} is required.`;
      if (!owner.currentAddress) errors[`currentAddress${index}`] = `Current address for Owner ${index + 1} is required.`;
      if (!owner.idFile) errors[`idFile${index}`] = `ID File for Owner ${index + 1} is required.`;
    });

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // If no errors, the form is valid
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Do not submit if validation fails
    }

    // Save form data in localStorage
    localStorage.setItem('companyDetails', JSON.stringify(companyDetails));
    localStorage.setItem('owners', JSON.stringify(owners));

    // Navigate to the payment page
    navigate("/payment");
  };

  const renderOwnerForms = () => {
    const ownerForms = [];
    for (let i = 0; i < companyDetails.numberOfOwners; i++) {
      ownerForms.push(
        <div key={i}>
          <h3>Owner {i + 1}</h3>
          <div>
            <label>First Name</label>
            <input
              type="text"
              name="firstName"
              onChange={(e) => handleOwnerChange(i, e)}
            />
            {formErrors[`firstName${i}`] && <p className="error">{formErrors[`firstName${i}`]}</p>}
          </div>
          <div>
            <label>Middle Name</label>
            <input
              type="text"
              name="middleName"
              onChange={(e) => handleOwnerChange(i, e)}
            />
          </div>
          <div>
            <label>Last Name</label>
            <input
              type="text"
              name="lastName"
              onChange={(e) => handleOwnerChange(i, e)}
            />
            {formErrors[`lastName${i}`] && <p className="error">{formErrors[`lastName${i}`]}</p>}
          </div>
          <div>
            <label>Date of Birth</label>
            <input
              type="date"
              name="dob"
              onChange={(e) => handleOwnerChange(i, e)}
            />
            {formErrors[`dob${i}`] && <p className="error">{formErrors[`dob${i}`]}</p>}
          </div>
          <div>
            <label>Current Address</label>
            <input
              type="text"
              name="currentAddress"
              onChange={(e) => handleOwnerChange(i, e)}
            />
            {formErrors[`currentAddress${i}`] && <p className="error">{formErrors[`currentAddress${i}`]}</p>}
          </div>
          <div>
            <label>Upload Identification (PDF, PNG, JPG, JPEG)</label>
            <input
              type="file"
              name="idFile"
              onChange={(e) => handleFileUpload(i, e)}
            />
            {formErrors[`idFile${i}`] && <p className="error">{formErrors[`idFile${i}`]}</p>}
          </div>
        </div>
      );
    }
    return ownerForms;
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Company Information</h2>
      <div>
        <label>What is your company name?</label>
        <input
          type="text"
          name="companyName"
          value={companyDetails.companyName}
          onChange={handleCompanyChange}
        />
        {formErrors.companyName && <p className="error">{formErrors.companyName}</p>}
      </div>
      <div>
        <label>What is your company's EIN?</label>
        <input
          type="text"
          name="companyEIN"
          value={companyDetails.companyEIN}
          onChange={handleCompanyChange}
        />
        {formErrors.companyEIN && <p className="error">{formErrors.companyEIN}</p>}
      </div>
      <div>
        <label>What is your company's address?</label>
        <input
          type="text"
          name="companyAddress"
          value={companyDetails.companyAddress}
          onChange={handleCompanyChange}
        />
        {formErrors.companyAddress && <p className="error">{formErrors.companyAddress}</p>}
      </div>
      <div>
        <label>How many owners does this business have?</label>
        <input
          type="number"
          name="numberOfOwners"
          value={companyDetails.numberOfOwners}
          onChange={handleCompanyChange}
        />
      </div>

      <h2>Owner Information</h2>
      {renderOwnerForms()}

      <button type="submit">Submit</button>
    </form>
  );
};

export default BOIRForm;
