import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className="about-us">
      <div className="container">
        
        <div className="intro-section">
          <h1>Welcome to Pioneer Engineering & Construction</h1>
          <p>
            Pioneer Engineering & Construction is a premier Somali-American owned company that stands at the forefront of the engineering and construction industry in East Africa. Our commitment to excellence, innovation, and integrity has positioned us as the region's leading engineering firm, distinguished by our international accreditations and global partnerships.
          </p>
        </div>

        <div className="legacy-section">
          <h2>Our Legacy</h2>
          <p>
            Founded with the vision to revolutionize the construction landscape in East Africa, Pioneer Engineering & Construction has grown from humble beginnings into a powerhouse with over 500 dedicated professionals. Our team is composed of world-class engineers, architects, project managers, and skilled workers, all of whom share a common goal: to deliver projects that not only meet but exceed our clients' expectations.
          </p>
        </div>

        <div className="recognition-section">
          <h2>Global Recognition</h2>
          <p>
            At Pioneer Engineering & Construction, we are proud to be the only engineering company in East Africa accredited by the prestigious International Accreditation Services (IAS). This recognition underscores our commitment to maintaining the highest standards of quality, safety, and sustainability in every project we undertake. Additionally, our membership in the International Engineering Alliance (IEA) connects us to a global network of leading engineering professionals.
          </p>
        </div>

        <div className="expertise-section">
          <h2>Our Expertise</h2>
          <p>
            With a rich portfolio of projects spanning across commercial, residential, industrial, and infrastructure sectors, we have consistently delivered landmark developments that contribute to the growth and modernization of East Africa. Our expertise encompasses everything from high-rise buildings and complex industrial facilities to sustainable infrastructure solutions and innovative engineering designs.
          </p>
        </div>

        <div className="people-section">
          <h2>Our People</h2>
          <p>
            Our greatest asset is our people. With a workforce of over 500 employees, we bring together a diverse team of talented individuals who are passionate about what they do. Our team members are not just employees; they are innovators, problem-solvers, and leaders who drive the success of our company.
          </p>
        </div>

        <div className="sustainability-section">
          <h2>Our Commitment to Sustainability</h2>
          <p>
            As a responsible corporate citizen, Pioneer Engineering & Construction is deeply committed to sustainability. We integrate eco-friendly practices into our projects, from the materials we use to the technologies we implement. Our goal is to create structures that are not only aesthetically pleasing and functional but also environmentally responsible.
          </p>
        </div>

        <div className="why-choose-us-section">
          <h2>Why Choose Us</h2>
          <p>
            Choosing Pioneer Engineering & Construction means partnering with a company that is dedicated to delivering exceptional results. We are more than just a construction company; we are a trusted partner who values your vision, respects your budget, and meets your deadlines.
          </p>
        </div>

        <div className="journey-section">
          <h2>Join Us on Our Journey</h2>
          <p>
            As we continue to build the future of East Africa, we invite you to join us on our journey of innovation, excellence, and growth. Whether you are a client, a partner, or a future employee, Pioneer Engineering & Construction is the place where your ambitions can be realized.
          </p>
        </div>
        
      </div>
    </div>
  );
};

export default AboutUs;
