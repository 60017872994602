import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="container">
        <div className="menu-icon" onClick={toggleMenu}>
          <i className={isOpen ? 'fas fa-times' : 'fas fa-bars'}></i>
        </div>
        <div className="logo">
          <Link to="/">
            <img src="https://pioneeriya.com//mybackend/images/logo.png" alt="Dhismobile Logo" />
          </Link>
        </div>
        <ul className={`nav-links ${isOpen ? 'active' : ''}`}>
          <li>
            <Link to="/" onClick={toggleMenu}>Home</Link>
          </li>
          <li>
            <Link to="/about-us" onClick={toggleMenu}>About Us</Link>
          </li>
          <li>
            <Link to="/services" onClick={toggleMenu}>Services</Link>
          </li>
          <li>
            <Link to="/projects" onClick={toggleMenu}>Projects</Link>
          </li>
          <li>
            <Link to="/investors" onClick={toggleMenu}>Investors</Link>
          </li>
          <li>
            <Link to="/jobs" onClick={toggleMenu}>Jobs</Link>
          </li>
           <li>
            <Link to="/boir-form" onClick={toggleMenu}>BOIR</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
