import React from 'react';
import './ContactUs.css';

const ContactUs = () => {
  return (
    <div className="contact-page">
      <div className="container">
        <div className="intro-section">
          <h1>Contact Us</h1>
          <p>
            We're here to assist you with any questions or inquiries you may have. Whether you're interested in our services, have a project in mind, or simply want to get in touch, we'd love to hear from you!
          </p>
        </div>

        <div className="contact-info">
          <h2>Reach Out to Us</h2>
          <p>We are available through the following contact methods:</p>
          
          <div className="contact-method">
            <h3>Email</h3>
            <p><a href="mailto:info@pioneer-construction.com">dhismopioneer@gmail.com</a></p>
          </div>
          

          
          <div className="contact-method">
            <h3>Mogadishu Office Address</h3>
            <p>Hodan-Taleex, Suite 105<br/>Mogadishu, Somalia<br/> Tel:0615523969<br/> WhatsApp:+252615523969</p>
            <h3>Seattle Office Address</h3>
            <p>6727 MLK Jr Way S STE E, <br/>Seattle, WA 98118<br/> Tel:2067855269<br/> WhatsApp:+12067855269</p>
          </div>
        </div>

       {/* <div className="contact-form">
          <h2>Send Us a Message</h2>
          <form>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" id="name" name="name" required />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" name="email" required />
            </div>

            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea id="message" name="message" rows="5" required></textarea>
            </div>

            <button type="submit">Send Message</button>
          </form>
        </div>*/}
      </div>
    </div>
  );
};

export default ContactUs;
