import React from 'react';
import { Link } from 'react-router-dom';
import './HomePage.css';

const HomePage = () => {
  return (
    <div className="home-page">
      {/* Hero Section */}
      <section className="hero">
        <div className="container">
          <h1>Pioneer Engineering & Construction</h1>
          <p>Building a brighter future across East Africa with innovation, quality, and excellence.</p>
          <a href="#services" className="cta-button">Our Services</a>
        </div>
      </section>

      {/* Introduction Section */}
      <section className="introduction">
        <div className="container">
          <h2>Welcome to Pioneer Engineering & Construction</h2>
          <p>
            We are a Somali-American owned company committed to revolutionizing the engineering and construction industry in East Africa. Our team of over 500 professionals is dedicated to delivering projects that meet the highest standards of quality, safety, and sustainability.
          </p>
        </div>
      </section>

      {/* Services Overview Section */}
      <section id="services" className="services-overview">
        <div className="container">
          <h2>Our Services</h2>
          <div className="service-cards">
            <div className="card">
              <h3>Civil Engineering</h3>
              <p>Expertise in designing and executing infrastructure projects that enhance connectivity and improve lives.</p>
            </div>
            <div className="card">
              <h3>Construction Management</h3>
              <p>Comprehensive management services to ensure projects are completed on time, within budget, and to the highest standards.</p>
            </div>
            <div className="card">
              <h3>Architectural Design</h3>
              <p>Innovative designs that blend functionality and aesthetics, tailored to your unique vision.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="cta-section">
        <div className="container">
          <h2>Ready to Start Your Next Project?</h2>
          <p>Contact us today to discuss your vision and how we can help bring it to life with our expertise and commitment to excellence.</p>
          <Link to="/contact-us" className="cta-button">Contact Us</Link>
        </div>
      </section>

      {/* Footer */}
      <footer className="footer">
        <div className="container">
          <p>&copy; 2024 Pioneer Engineering & Construction. All rights reserved.</p>
          <p>Follow us on 
            <a href="https://www.facebook.com"> Facebook</a>, 
            <a href="https://www.twitter.com"> Twitter</a>, and 
            <a href="https://www.linkedin.com"> LinkedIn</a>.
          </p>
        </div>
      </footer>

      {/* Bottom Navigation Menu for Mobile */}
      {/*<nav className="bottom-nav">
        <Link to="/" className="bottom-nav-link">Home</Link>
        <Link to="/about-us" className="bottom-nav-link">About Us</Link>
        <Link to="/services" className="bottom-nav-link">Services</Link>
        <Link to="/contact-us" className="bottom-nav-link">Contact Us</Link>
      </nav>*/}
    </div>
  );
};

export default HomePage;
