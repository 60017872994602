import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faHandshake, faToolbox, faTasks } from '@fortawesome/free-solid-svg-icons';
import './BottomNav.css'; // Ensure you create this CSS file for styling

const BottomNav = () => {
  return (
    <nav className="bottom-nav">
      <Link to="/" className="bottom-nav-link">
        <FontAwesomeIcon icon={faHome} />
        <span>Home</span>
      </Link>
      <Link to="/investors" className="bottom-nav-link">
        <FontAwesomeIcon icon={faHandshake} />
        <span>Investors</span>
      </Link>
      <Link to="/services" className="bottom-nav-link">
        <FontAwesomeIcon icon={faToolbox} />
        <span>Services</span>
      </Link>
      <Link to="/projects" className="bottom-nav-link">
        <FontAwesomeIcon icon={faTasks} />
        <span>Projects</span>
      </Link>
    </nav>
  );
};

export default BottomNav;
