import React, { useState } from 'react';

const EmailVerification = () => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Regular expression for validating email format
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrorMessage(''); // Clear the error message when user types
    setSuccessMessage('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (emailRegex.test(email)) {
      // Send POST request to PHP backend
      fetch('http://locahost:8888/mybackend/sendEmail.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          email: email,
        }),
      })
        .then((response) => response.text())
        .then((data) => {
          setSuccessMessage(data);
        })
        .catch((error) => {
          setErrorMessage('Error sending email.');
          console.error('Error:', error);
        });
    } else {
      setErrorMessage('Please enter a valid email');
    }
  };

  return (
    <div>
      <h2>Email Verification</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="Enter your email"
            required
          />
        </div>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
        <button type="submit">Send Verification Email</button>
      </form>
    </div>
  );
};

export default EmailVerification;
