import React, { useState } from 'react';
import { PaymentForm, CreditCard } from 'react-square-web-payments-sdk';
import axios from 'axios';

const SquarePayment = () => {
  const [paymentStatus, setPaymentStatus] = useState('');

  // Function to send form data and email after payment
  const submitFormAndSendEmail = async (token) => {
    const companyDetails = JSON.parse(localStorage.getItem('companyDetails'));
    const owners = JSON.parse(localStorage.getItem('owners'));

    const formData = new FormData();

    // Append company details
    formData.append('companyName', companyDetails.companyName);
    formData.append('companyEin', companyDetails.companyEIN);
    formData.append('companyAddress', companyDetails.companyAddress);
    formData.append('token', token); // Add payment token

    // Append owner details
    owners.forEach((owner, index) => {
      formData.append(`owners[${index}][firstName]`, owner.firstName);
      formData.append(`owners[${index}][middleName]`, owner.middleName);
      formData.append(`owners[${index}][lastName]`, owner.lastName);
      formData.append(`owners[${index}][dob]`, owner.dob);
      formData.append(`owners[${index}][currentAddress]`, owner.currentAddress);

      // Append file (ID)
      if (owner.idFile) {
        formData.append(`owners[${index}][idFile]`, owner.idFile);
      }
    });

    try {
      // Send form data and payment token to your backend
      const response = await axios.post("https://pioneeriya.com/mybackend/sendForm.php", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });

      if (response.status === 200) {
        setPaymentStatus('Form submitted and email sent successfully!');
      }
    } catch (error) {
      console.error("Error submitting form and sending email:", error);
      setPaymentStatus('Error submitting form or sending email.');
    }
  };

  return (
    <div>
      <h1>Square Payment Integration</h1>
      <PaymentForm
        applicationId="sandbox-sq0idb-R1GZSK9-t3vuey4nAV9RyQ" // Replace with your Square Sandbox Application ID
        locationId="L5Q71YYD1KCZ1" // Replace with your Location ID
        cardTokenizeResponseReceived={(token, buyer) => {
          if (token) {
            setPaymentStatus('Payment successful! Submitting form...');
            submitFormAndSendEmail(token.token); // Submit form after payment
          } else {
            setPaymentStatus('Payment failed. Please try again.');
          }
        }}
        createVerificationDetails={() => ({
          amount: '100.00',
          currencyCode: 'USD',
          intent: 'CHARGE',
          billingContact: {
            familyName: 'Doe',
            givenName: 'John',
            email: 'john.doe@example.com',
          },
        })}
      >
        <CreditCard />
        <button style={{ marginTop: '20px', padding: '10px', fontSize: '16px' }}>
          Pay Now
        </button>
      </PaymentForm>
      <p>{paymentStatus}</p>
    </div>
  );
};

export default SquarePayment;
